import React from "react";
import { Image, NewLogo } from "@kanpla/ui";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

interface ButtonProps {
  title: string;
  className?: string;
  type?: "primary" | "secondary";
  onClick: () => void;
}

const LandingPageButton = (props: ButtonProps) => {
  const { title, className = "", type = "primary", onClick } = props;

  return (
    <div
      role="button"
      className={`flex hover:shadow-md transition-shadow ${
        type === "primary"
          ? "bg-primary-main text-primary-contrast"
          : "bg-secondary-main text-secondary-contrast"
      } font-semibold cursor-pointer w-full md:max-w-sm h-16 md:h-14 rounded-lg justify-center tracking-wide items-center ${className}`}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

const Landing = () => {
  const { t } = useTranslation(["translation"]);
  const router = useRouter();
  const { customBranding, customBrandingLoading, mobile } =
    useContainer(AppContext);

  const landingBackgroundType = mobile ? "mobile" : "desktop";
  const landingBackgroundImage =
    customBranding?.custom?.images?.landingBackgroundImage;

  const backgroundImage = !customBrandingLoading
    ? landingBackgroundImage?.[landingBackgroundType] ||
      // If mobile image is not available, fallback to the desktop one
      (mobile && landingBackgroundImage?.["desktop"]) ||
      customBranding?.custom?.images?.landingBackground ||
      "/images/cooking-hero-join.jpeg"
    : null;

  return (
    <div id="landing-app-fullscreen" className="w-full h-full relative">
      <div>
        <div className="z-0 absolute inset-0">
          <div className="bg-primary-main bg-opacity-30 absolute inset-0 z-10" />
          <div className="bg-gradient-to-tl from-black opacity-70 absolute inset-0 z-10" />
          <Image
            src={backgroundImage}
            alt={undefined}
            className="absolute inset-0 z-0 w-full h-full object-cover"
          />
        </div>
        <div className="relative md:flex md:justify-center md:items-center w-full h-full p-4 z-40 min-h-screen">
          <div className="flex flex-col justify-between md:justify-center w-full h-full p-4 fixed inset-0">
            <div className="w-full flex justify-center mt-20 md:mt-0">
              <NewLogo centered type="white" style={{ maxWidth: 200 }} />
            </div>
            <div className="flex flex-col md:justify-center md:items-center mb-8 md:mt-20">
              {!customBranding?.posone && (
                <LandingPageButton
                  onClick={() => router.push("/signup")}
                  title={t("translation:sign-up")}
                />
              )}
              {customBranding?.posone && customBranding?.url && (
                <a
                  href={`https://${customBranding?.url}.smartbetaling.dk/#register`}
                  className="w-full md:max-w-sm no-underline"
                >
                  <LandingPageButton
                    onClick={() => {
                      return;
                    }}
                    title={t("translation:sign-up")}
                  />
                </a>
              )}
              <LandingPageButton
                title={t("translation:log-in")}
                type="secondary"
                onClick={() => router.push("/login")}
                className="mt-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
